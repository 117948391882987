//
//
//
//
//
//
export default {
  props: {
    info: {
      type: String,
      default: "一只小毛驴"
    },
    color: {
      type: String
    }
  },
  data: function data() {
    return {
      bg: {
        "background": this.color
      }
    };
  }
};